import './textfield.scss';
import './select-button.scss';

import Textfield from './Textfield';

const Termin = (props) => {

    const { reducerState, dispatch, index } = props;

    return <div className="termin-row">
        <h2>Termin {index + 1}</h2>
        <Textfield
            value={reducerState[index].betreff}
            onChange={(e) =>
                dispatch({
                    type: 'UPDATE_TEXT',
                    value: e,
                    key: 'betreff',
                    index: index
                })}
            placeholder="Betreff" />
        <Textfield
            value={reducerState[index].ort}
            onChange={(e) =>
                dispatch({
                    type: 'UPDATE_TEXT',
                    value: e,
                    key: 'ort',
                    index: index
                })}
            placeholder="Ort" />
        <div className={'input-wrapper--termin'}>

            <label>Start</label>
            <input
                value={reducerState[index].startDay}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE_START',
                        value: e.target.value,
                        key: 'startDay',
                        index: index
                    })} placeholder={"Tag"} />
            <input
                value={reducerState[index].startMonth}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE_START',
                        value: e.target.value,
                        key: 'startMonth',
                        index: index
                    })} placeholder={"Monat"} />
            <input
                value={reducerState[index].startYear}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE_START_YEAR',
                        value: e.target.value,
                        key: 'startYear',
                        index: index
                    })} placeholder={"Jahr"} />
            <input
                value={reducerState[index].startHour}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: e.target.value,
                        key: 'startHour',
                        index: index
                    })} placeholder={"hh"} />
            <input
                value={reducerState[index].startMin}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: e.target.value,
                        key: 'startMin',
                        index: index
                    })} placeholder={"mm"} />
        </div><div className={'input-wrapper--termin'}>
            <label>Ende</label>
            <input
                value={reducerState[index].endDay}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: e.target.value,
                        key: 'endDay',
                        index: index
                    })} placeholder={"Tag"} />
            <input
                value={reducerState[index].endMonth}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: e.target.value,
                        key: 'endMonth',
                        index: index
                    })} placeholder={"Monat"} />
            <input
                value={reducerState[index].endYear}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE_YEAR',
                        value: e.target.value,
                        key: 'endYear',
                        index: index
                    })} placeholder={"Jahr"} />
            <input
                value={reducerState[index].endHour}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: e.target.value,
                        key: 'endHour',
                        index: index
                    })} placeholder={"hh"} />
            <input
                value={reducerState[index].endMin}
                onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: e.target.value,
                        key: 'endMin',
                        index: index
                    })} placeholder={"mm"} />
        </div>
    </div>;
};

Termin.defaultProps = {
    type: 'text'
}

export default Termin;