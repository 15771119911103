import axios from "axios";
import { refreshToken } from "./requests";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

axios.defaults.baseURL = API_ENDPOINT;

const instance = axios.create({
    headers: {
        "Content-Type": "application/json",
        //'Cache-Control': 'no-store',
    },
});

instance.interceptors.request.use(
    (config) => {
        /*
        const token = "Bearer " + window.localStorage.getItem("accessToken")
        if (token) {
            config.headers["Authorization"] = token;
        }*/
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(

    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;
                try {
                    const rs = await refreshToken();
                    const accessToken = rs.data.data.access_token;
                    window.localStorage.setItem("accessToken", accessToken);
                    axios.defaults.headers.common['Authorization'] = "Bearer " + accessToken;

                    return instance(originalConfig);
                } catch (_error) {
                    if (_error.response && _error.response.data) {
                        return Promise.reject(_error.response.data);
                    }

                    return Promise.reject(_error);
                }
            }

            if (err.response.status === 403 && err.response.data) {
                return Promise.reject(err.response.data);
            }
        }

        return Promise.reject(err);
    }
);

export default instance;