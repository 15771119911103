import React, { useReducer, useState, useEffect } from 'react'
import Button from '../../components/Button';

import { useAuth } from '../../config/FormContext';
import { useNavigate } from "react-router-dom";
import Termin from '../../components/Termin';

import './termine.scss';

const initialState = {
    startDay: '',
    endDay: '',
    startMonth: '',
    endMonth: '',
    startYear: '',
    endYear: '',
    startHour: '',
    startMin: '',
    endHour: '',
    endMin: '',
    ort: '',
    betreff: ''
}

const termine = { 0: initialState }

function reducer(state, action) {
    let tmpState, tmpState2, array;
    switch (action.type) {
        case 'UPDATE':
            tmpState = state[action.index];
            tmpState2 = { ...tmpState, [action.key]: action.value }

            if (isNaN(action.value) || action.value.length > 2) {
                return {
                    ...state,
                }
            }

            return {
                ...state, [action.index]: tmpState2,
            }
        case 'UPDATE_YEAR':
            tmpState = state[action.index];
            tmpState2 = { ...tmpState, [action.key]: action.value }

            if (isNaN(action.value) || action.value.length > 4) {
                return {
                    ...state,
                }
            }

            return {
                ...state, [action.index]: tmpState2,
            }
        case 'UPDATE_START':
            if (isNaN(action.value) || action.value.length > 2) {
                return {
                    ...state,
                }
            }
            tmpState = state[action.index];
            tmpState2 = { ...tmpState, [action.key]: action.value, [action.key.replace('start', 'end')]: action.value }
            return {
                ...state, [action.index]: tmpState2,
            }
        case 'UPDATE_START_YEAR':
            if (isNaN(action.value) || action.value.length > 4) {
                return {
                    ...state,
                }
            }
            tmpState = state[action.index];
            tmpState2 = { ...tmpState, [action.key]: action.value, [action.key.replace('start', 'end')]: action.value }
            return {
                ...state, [action.index]: tmpState2,
            }
        case 'UPDATE_TEXT':

            tmpState = state[action.index];
            tmpState2 = { ...tmpState, [action.key]: action.value }
            return {
                ...state, [action.index]: tmpState2,
            }
        case 'ADD_NEW':
            array = {
                ...state
            };
            array[action.payload] = initialState;
            return array;
        case 'REMOVE':
            array = {
                ...state
            };
            delete array[action.payload];
            return array;
        case 'REPLACE':
            return action.payload;
        default:
            return state
    }
}

export default function Termine() {
    const [reducerState, dispatch] = useReducer(reducer, termine);
    const [anzahlTermine, setAnzahlTermine] = useState(1);
    const { tmpSave } = useAuth();
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        tmpSave({ termine: reducerState })

        navigate('/files')

    }

    const addTermin = (e) => {

        dispatch({ type: 'ADD_NEW', payload: anzahlTermine });
        setAnzahlTermine((prev) => (prev + 1));


    }

    const delTermin = (e) => {

        dispatch({ type: 'REMOVE', payload: anzahlTermine });
        setAnzahlTermine((prev) => ((prev - 1) > 0 ? (prev - 1) : 1));
    }

    useEffect(() => {

        if (window.localStorage.getItem('formData')) {
            const { termine } = JSON.parse(window.localStorage.getItem('formData'));
            if (termine) {
                dispatch({ type: 'REPLACE', payload: { ...initialState, ...termine } })

            }
        }
    }, []);

    return (
        <div className='add-new'>
            <h1>Termine erfassen</h1>
            <p>Hier kannst du die Termine zu deinem Angebot erfassen. Wenn dasselbe Angebot öfters als 1x statt findet, dann kannst du einen weiteren Termin hinzufügen.</p>

            <form onSubmit={e => { handleSubmit(e) }}>

                <div>
                    {[...Array(anzahlTermine)].map((key, index) => (

                        <Termin
                            reducerState={reducerState}
                            dispatch={dispatch}
                            value={reducerState.termin}
                            index={index}
                            key={index}
                            onChange={(e) =>
                                dispatch({
                                    type: 'UPDATE',
                                    value: e,
                                    key: 'termin',
                                })}
                            placeholder="Termin" />
                    ))}



                </div>
                <div className="button-wrapper">
                    <button
                        onClick={delTermin}
                        type="button"
                    >letzten Termin löschen</button>
                    <button onClick={addTermin} type="button"
                    >weiterer Termin</button>
                </div>

                <Button

                    type='submit'
                    value='weiter'
                />
            </form>
        </div>
    )
}
