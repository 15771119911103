import React, { useState, createContext, useContext, useEffect } from "react";
import instance from "./axiosConfig";
// Create the context 
const FormContext = createContext(null);


export const FormProvider = ({ children }) => {
    const [headerbild, setHeaderbildContext] = useState(null);
    const [logo, setLogoContext] = useState(null);
    const [gallery, setGalleryContext] = useState(null);
    const [status, setStatus] = useState(null);



    const finalPost = async () => {

        const localStorageData = JSON.parse(window.localStorage.getItem('formData'));
        const headerbild = window.localStorage.getItem('headerbild');
        const logo = window.localStorage.getItem('logo');
        window.localStorage.removeItem('logo');
        window.localStorage.removeItem('headerbild');

        const body = { form_data: localStorageData, headerbild: headerbild, logo: logo, gallery: gallery }
        const collection = 'bg_angebote';
        await instance.post("/items/" + collection, body)
            .then((res) => {
                console.log(res);
                console.log(res.data.data.id);
                localStorage.setItem('id', res.data.data.id);
                setStatus(true);
            })
            .catch((err) => { console.log(err); alert("ups, etwas ist schief gegangen.... Melde dich bei dario@bikegenoss.ch!") });
    };

    /*

    useEffect(() => {
        const id = window.localStorage.getItem('id');
        const isEmpty = Object.keys(formData).length === 0;


        if (isEmpty) {
            if (id === null) {
                return;
            }
            console.log('load from server!')
            getData(id);
            return;

        }

        if (id === null) {
            const body = { form_data: formData, headerbild: headerbild, logo: logo, gallery: gallery }
            postData(body, 'bg_angebote');

            console.log("create");
            console.log({ body: formData });
        } else {
            const body = { form_data: formData, headerbild: headerbild, logo: logo, gallery: gallery }
            updateData(body, 'bg_angebote', id);
            console.log("update");

        }



    }, [formData, headerbild, logo, gallery]);*/

    useEffect(() => { console.log(window.localStorage.getItem('headerbild')) });


    const tmpSave = (data) => {
        const localStorageData = JSON.parse(window.localStorage.getItem('formData'));
        const newLocalStorageData = { ...localStorageData, [Object.keys(data)[0]]: Object.values(data)[0] };
        window.localStorage.setItem('formData', JSON.stringify(newLocalStorageData));

    }

    /*
    const getUser = async () => {
        await instance.get("/users/me")
            .then((res) => {
                const user = (({ first_name, last_name, user_group }) => ({ first_name, last_name, user_group }))(res.data.data);
                setUser(user);
            })
            .catch((err) => { });
    };*/


    return (
        // Using the provider so that ANY component in our application can 
        // use the values that we are sending.
        <FormContext.Provider value={{ setHeaderbildContext, setLogoContext, setGalleryContext, finalPost, tmpSave, status, setStatus }}>
            {children}
        </FormContext.Provider>
    );
};

// Finally creating the custom hook 
export const useAuth = () => useContext(FormContext);