import React, { useReducer, useEffect, useState } from 'react'
import './overview.scss';
import Textfield from '../../components/Textfield';
import Button from '../../components/Button';

import { useAuth } from '../../config/FormContext';
import { useNavigate } from "react-router-dom";

import instance from '../../config/axiosConfig';
import axios from 'axios';

import { Link } from 'react-router-dom';


export default function Overview() {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const WP_ENDPOINT = process.env.REACT_APP_WP_API_ENDPOINT;


    const client = axios.create({
        baseURL: `${WP_ENDPOINT}/wp-json/bikegenoss/v1/`,
    });

    const [data, setData] = useState(null);

    const getData = async (id) => {
        await instance.get("/items/bg_angebote/")
            .then((res) => {
                //setFormData(res.data.data)
                const response = res.data;
                console.log(response.data)
                setData(response.data);
                console.log(Object.keys(JSON.parse(response.data[7].form_data).termine).length)
            })
            .catch((err) => { console.log(err) });
    };

    const numberOfDates = (obj) => {
        if (obj == null) return 0;
        return Object.keys(obj).length;
    }

    const printValue = (obj) => {
        if (obj === "") return "-";
        return obj;
    }



    const mapObject = (obj) => {
        const formData = JSON.parse(obj.form_data);
        console.log(formData);
        let body = {};

        console.log(obj);

        body.id = obj.id;
        body.title = formData.angebot.titel;
        body.teaser = formData.angebot.teaser;
        body.beschreibung = formData.angebot.beschreibung;
        body.type = formData.angebot.type;
        body.kanton = formData.details.kanton;
        body.link = formData.details.link;
        body.preis = formData.details.preis;
        body.ort = formData.details.ort;
        body.headerbild = API_ENDPOINT + "/assets/" + obj.headerbild + '.jpg';

        body.termine = JSON.stringify(createDatesObject(formData.termine));
        console.log(body);
        postData(body);
    }

    const createDatesObject = (termine) => {
        let dates = [];
        for (let i = 0; i < numberOfDates(termine); i++) {
            const date = {
                datum_start: termine[i].startDay + '.' + termine[i].startMonth + '.' + termine[i].startYear + ' ' + termine[i].startHour + ':' + termine[i].startMin,
                datum_ende: termine[i].endDay + '.' + termine[i].endMonth + '.' + termine[i].endYear + ' ' + termine[i].endHour + ':' + termine[i].endMin,

            }
            dates.push(date);

        }
        return dates;

    }

    const postData = async (body) => {
        var form_data = new FormData();

        for (var key in body) {
            form_data.append(key, body[key]);
        }

        await client.post("/event/", form_data)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => { console.log(err) });
    };


    useEffect(() => {
        localStorage.removeItem('id');

        getData();

    }, []);

    return (
        <div className='add-new'>
            <h1>Übersicht</h1>
            <p>Fülle bitte zuerst deine Kontaktdaten aus, so können wir die Angebote der richtigen Person zuordnen. Auf den nächsten Seiten kannst du alle wichtigen Informationen zu deinem Angebot erfassen.</p>
            <div className={"row row--header"}>

                <span>Name</span>
                <span>Organisation</span>
                <span className="narrow">#Termine</span>
                <span className="narrow">Bild</span>
                <span className="narrow">Logo</span>
                <span className="narrow">Sync</span>



            </div>
            {data ? data.map((item) => (
                <div className={"row"} key={item.id}>

                    {JSON.parse(item.form_data).angebot ?
                        <span><Link to={item.id.toString()} path="relative">{printValue(JSON.parse(item.form_data).angebot.titel)}</Link></span> : null}

                    {JSON.parse(item.form_data).organisation ?
                        <span>{printValue(JSON.parse(item.form_data).organisation.organisation)}</span> : null}

                    <span className="narrow">{numberOfDates(JSON.parse(item.form_data).termine)}</span>
                    <span className="narrow">{(item.headerbild) ? 'yes' : 'no'}</span>
                    <span className="narrow">{(item.logo) ? 'yes' : 'no'}</span>

                    <span className="narrow" onClick={() => mapObject(item)}>sync</span>

                </div >
            )) : null
            }

        </div >
    )
}
