import React, { useReducer, useEffect, useState } from 'react'
import './single-view.scss';
import Textfield from '../../components/Textfield';
import Button from '../../components/Button';

import { useAuth } from '../../config/FormContext';
import { useNavigate } from "react-router-dom";

import instance from '../../config/axiosConfig';
import axios from 'axios';

import { useParams } from 'react-router-dom';

export default function SingleView() {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const WP_ENDPOINT = process.env.REACT_APP_WP_API_ENDPOINT;


    const client = axios.create({
        baseURL: `${WP_ENDPOINT}/wp-json/bikegenoss/v1/`,
    });

    const [data, setData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [wpid, setWpid] = useState('');

    const { id } = useParams();

    const getData = async (id) => {
        await instance.get("/items/bg_angebote/" + id)
            .then((res) => {
                //setFormData(res.data.data)
                const response = res.data;
                console.log(response.data)
                setData(response.data);

                setFormData(JSON.parse(response.data.form_data));
                console.log(JSON.parse(response.data.form_data).termine);
            })
            .catch((err) => { console.log(err) });
    };

    const numberOfDates = (obj) => {
        if (obj == null) return 0;
        return Object.keys(obj).length;
    }

    const printValue = (obj) => {
        if (obj === "") return "-";
        return obj;
    }



    const mapObject = (obj) => {
        const formData = JSON.parse(obj.form_data);
        console.log(formData);
        let body = {};

        console.log(obj);

        body.id = obj.id;
        body.title = formData.angebot.titel;
        if (formData.angebot.teaser !== undefined && formData.angebot.teaser !== "") {
            body.teaser = formData.angebot.teaser;
        }
        if (formData.angebot.beschreibung !== undefined && formData.angebot.beschreibung !== "") {
            body.beschreibung = formData.angebot.beschreibung;

        }
        if (formData.angebot.type !== undefined && formData.angebot.type !== "") {
            body.type = formData.angebot.type;

        }
        if (formData.details.kanton !== undefined && formData.details.kanton !== "") {
            body.kanton = formData.details.kanton;
        }
        if (formData.details.link !== undefined && formData.details.link !== "") {
            body.link = formData.details.link;
        }
        if (formData.details.preis !== undefined && formData.details.preis !== "") {
            body.preis = formData.details.preis;
        }


        if (formData.details.ort !== undefined && formData.details.ort !== "") {
            body.ort = formData.details.ort;
        }

        if (obj.headerbild !== undefined && obj.headerbild !== "" && obj.headerbild !== null) {
            body.headerbild = API_ENDPOINT + "/assets/" + obj.headerbild + '.jpg';
        }

        if (obj.wpid !== undefined && obj.wpid !== "") {
            body.wp_id = parseInt(obj.wpid);
        }

        body.organisator = 296;

        body.termine = JSON.stringify(createDatesObject(formData.termine));
        console.log(body);
        postData(body);
    }

    const createDatesObject = (termine) => {
        let dates = [];

        Object.keys(formData.termine).forEach((key, i) => {
            if (formData.termine[i]) {
                const date = {
                    datum_start: termine[i].startDay + '.' + termine[i].startMonth + '.' + termine[i].startYear + ' ' + termine[i].startHour + ':' + termine[i].startMin,
                    datum_ende: termine[i].endDay + '.' + termine[i].endMonth + '.' + termine[i].endYear + ' ' + termine[i].endHour + ':' + termine[i].endMin,

                }
                dates.push(date);
            }
        })
        /*
        for (let i = 0; i < numberOfDates(termine); i++) {
            if (typeof termine[i].startDay !== 'undefined') {
                const date = {
                    datum_start: termine[i].startDay + '.' + termine[i].startMonth + '.' + termine[i].startYear + ' ' + termine[i].startHour + ':' + termine[i].startMin,
                    datum_ende: termine[i].endDay + '.' + termine[i].endMonth + '.' + termine[i].endYear + ' ' + termine[i].endHour + ':' + termine[i].endMin,
     
                }
                dates.push(date);
            }
        }*/
        return dates;

    }

    const postData = async (body) => {
        var form_data = new FormData();

        for (var key in body) {
            form_data.append(key, body[key]);
        }

        await client.post("/event/", form_data)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => { console.log(err) });
    };


    useEffect(() => {
        localStorage.removeItem('id');

        getData(id);

    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(wpid);
        if (wpid !== "") {
            mapObject({ ...data, ['wpid']: wpid });
        } else {
            mapObject(data);
        }


    }

    return (
        <div className='add-new'>
            <h1>Details</h1>
            <p>Fülle bitte zuerst deine Kontaktdaten aus, so können wir die Angebote der richtigen Person zuordnen. Auf den nächsten Seiten kannst du alle wichtigen Informationen zu deinem Angebot erfassen.</p>

            {data ?
                <div>
                    <div className="angebot-wrapper">
                        <form onSubmit={e => { handleSubmit(e) }}>
                            <Textfield
                                value={wpid}
                                onChange={(e) => setWpid(e)}
                                placeholder="WordPress Id" />
                            <Button
                                type='submit'
                                value='Sync'
                            />
                        </form>
                    </div>
                    <div className="angebot-wrapper">
                        <h3>{formData.organisation.organisation ? formData.organisation.organisation : "-"}</h3>
                        <div>{formData.organisation.vorname} {formData.organisation.nachname}</div>
                        <div>{formData.organisation.email}</div>
                    </div>
                    <div className="angebot-wrapper">
                        <h2>{formData.angebot.type}</h2>
                        <div>{formData.details.kanton}</div>
                        <div><a href={formData.details.link} target="__blank">{formData.details.link}</a></div>
                        <div>{formData.details.ort}</div>
                        <div>{formData.details.preis}</div>
                    </div>
                    <div className="angebot-wrapper">
                        <h2>{formData.angebot.titel}</h2>
                        <p>{formData.angebot.teaser}</p>
                        <p>{formData.angebot.beschreibung}</p>
                    </div>
                    <div className="angebot-wrapper">
                        {Object.keys(formData.termine).map((key, index) => {
                            if (formData.termine[index]) {
                                return <div key={index}>{formData.termine[index].betreff}</div>;
                            }
                            else {
                                return null;
                            }
                        })}


                    </div>

                </div>
                : null}


        </div >
    )
}
