import React, { useReducer, useEffect } from 'react'
import Textfield from '../../components/Textfield';
import Button from '../../components/Button';
import Select from '../../components/Select';

import { useAuth } from '../../config/FormContext';
import { useNavigate } from "react-router-dom";

const initialState = {
    link: '',
    preis: '',
    kanton: '',
    ort: '',
}

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state, [action.key]: action.value,
            }
        case 'REPLACE':
            return action.payload;
        default:
            return state
    }
}

export default function Details() {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const { tmpSave } = useAuth();
    const navigate = useNavigate();
    const handleSubmit = (e) => {
        e.preventDefault();
        tmpSave({ details: reducerState })

        navigate('/termine')
    }

    const options = [
        { value: 'none', text: 'Kanton auswählen', disabled: 'disabled', selected: 'defaultValue', hidden: 'hidden' },
        { value: 'Nidwalden', text: 'Nidwalden' },
        { value: 'Obwalden', text: 'Obwalden' },
        { value: 'Luzern', text: 'Luzern' },
        { value: 'Uri', text: 'Uri' },
        { value: 'Schwyz', text: 'Schwyz' },
        { value: 'Zug', text: 'Zug' },
    ];

    useEffect(() => {
        const { details } = JSON.parse(window.localStorage.getItem('formData'));
        dispatch({ type: 'REPLACE', payload: { ...initialState, ...details } })
    }, []);

    return (
        <div className='add-new'>
            <h1>Details erfassen</h1>
            <p>Hier noch ein paar Detail-Informationen zu deinem Angebot.</p>

            <form onSubmit={e => { handleSubmit(e) }}>
                <Textfield
                    value={reducerState.link}
                    onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'link',
                        })}
                    placeholder="Link zur Webseite" />
                <Textfield
                    value={reducerState.preis}
                    onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'preis',
                        })}
                    placeholder='Preis: "CHF xx:00" oder "ab CHF xx:00"' />
                <Select options={options} value={reducerState.kanton} onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: e,
                        key: 'kanton',
                    })} />
                <Textfield
                    value={reducerState.ort}
                    onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'ort',
                        })}
                    placeholder="Ort" />
                <Button
                    type='submit'
                    value='weiter'
                />
            </form>
        </div>
    )
}
