import './textfield.scss';

const Textarea = (props) => {
    const { value, onChange, placeholder, rows } = props;
    const handleChange = (e) => {
        e.preventDefault();
        onChange(e.target.value);
    }

    return <div className={'input-wrapper'}><textarea value={value} onChange={handleChange} placeholder={placeholder} rows={rows} /></div>;
};

Textarea.defaultProps = {
    rows: 4
}

export default Textarea;