import Compressor from 'compressorjs';

const compressImage = (file, quality) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: quality,
            maxWidth: 2400,
            maxHeight: 2400,
            success: (result) => {
                resolve(new File([result], file.name, { type: result.type }));
            },
            error: (error) => reject(error)
        })
    })
}

const iterativeImageCompression = async (inputFile) => {
    let counter = 10;
    let quality = 1;
    let size = inputFile.size / (1000 * 1000);
    let factor = 1;
    let maxSize = 1.2;
    let prevSize = null;
    let file;

    while (counter > 0 && size > maxSize) {
        counter--;
        quality = Math.round((quality - factor * 0.05) * 20) / 20;
        file = await compressImage(inputFile, quality);
        size = file.size / (1000 * 1000);
        factor = Math.max(Math.round(size / maxSize), 1);
        if (size === prevSize) break;
        prevSize = size;
    }

    return file ? file : inputFile;
}

const toDataURL = url => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
    }))



function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}



export { compressImage, iterativeImageCompression, toDataURL, dataURLtoFile };