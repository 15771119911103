import './add-new.scss';

import { useAuth } from '../../config/FormContext';
import { useNavigate } from "react-router-dom";

import { useEffect } from 'react';

export default function Success() {
    const navigate = useNavigate();
    const { formData, setFormData, tmpSave } = useAuth();

    const backHome = () => {
        const { organisation } = JSON.parse(window.localStorage.getItem('formData'));
        window.localStorage.removeItem('formData');

        tmpSave({ organisation: organisation })

        navigate("/");
    }

    return (
        <div className='add-new'>
            <h1>Super!</h1>
            <p>Wir haben deine Daten erhalten und werden die Angebote so bald wie möglich aufschalten. Vielen Dank!</p>
            <button
                onClick={backHome}
                type="button"
            > weiteres Angebot erfassen</button>

        </div >
    )
}
