import React, { useReducer, useEffect } from 'react'
import './add-new.scss';
import Textfield from '../../components/Textfield';
import Button from '../../components/Button';

import { useAuth } from '../../config/FormContext';
import { useNavigate } from "react-router-dom";

const initialState = {
    vorname: '',
    nachname: '',
    email: '',
    organisation: '',
}

function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state, [action.key]: action.value,
            }
        case 'REPLACE':
            return action.payload;
        default:
            return state
    }
}

export default function AddNew() {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const { tmpSave } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();

        tmpSave({ organisation: reducerState })
        navigate('/angebot')
    }

    useEffect(() => {
        if (window.localStorage.getItem('formData')) {
            const { organisation } = JSON.parse(window.localStorage.getItem('formData'));
            dispatch({ type: 'REPLACE', payload: { ...initialState, ...organisation } })
        }

    }, []);

    return (
        <div className='add-new'>
            <h1>Kontakt</h1>
            <p>Fülle bitte zuerst deine Kontaktdaten aus, so können wir die Angebote der richtigen Person zuordnen. Auf den nächsten Seiten kannst du alle wichtigen Informationen zu deinem Angebot erfassen.</p>

            <form onSubmit={e => { handleSubmit(e) }}>
                <div className="columns-2">
                    <Textfield
                        value={reducerState.vorname}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'vorname',
                            })}
                        placeholder="Vorname" />
                    <Textfield
                        value={reducerState.nachname}
                        onChange={(e) =>
                            dispatch({
                                type: 'UPDATE',
                                value: e,
                                key: 'nachname',
                            })}
                        placeholder="Nachname" />
                </div>
                <Textfield
                    value={reducerState.email}
                    onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'email',
                        })}
                    placeholder="Email" />
                <Textfield
                    value={reducerState.organisation}
                    onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'organisation',
                        })}
                    placeholder="Organisation" />
                <Button
                    type='submit'
                    value='weiter'
                />
            </form>
        </div>
    )
}
