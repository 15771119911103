import logo from './bikegenoss_ozs_pos_rgb.svg';
import './App.css';

import AddNew from './containers/AddNew/AddNew'
import Angebot from './containers/Angebot/Angebot';
import Details from './containers/Details/Details';
import Termine from './containers/Termine/Termine';
import { Route, Routes } from 'react-router-dom';
import { FormProvider } from './config/FormContext';
import Success from './containers/AddNew/Success';
import Files from './containers/Files/Files';
import Overview from './containers/Overview/Overview';
import SingleView from './containers/SingleView/SingleView';
import DataHub from './containers/DataHub/DataHub';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <div className="form-container">
        <FormProvider>

          <Routes>
            <Route path="/angebot" element={<Angebot />} />
            <Route path="/details" element={<Details />} />
            <Route path="/termine" element={<Termine />} />
            <Route path="/success" element={<Success />} />
            <Route path="/files" element={<Files />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/overview/:id" element={<SingleView />} />
            <Route path="/dch" element={<DataHub />} />

            <Route path="/" element={<AddNew />} />
          </Routes>
        </FormProvider>

      </div>
    </div>
  );
}

export default App;
