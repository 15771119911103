import axios from "axios";
import instance from './axiosConfig';


export function refreshToken() {
    return axios.post("/auth/refresh", { withCredentials: true });
}

export async function login(email, password) {
    const auth = {
        "email": email,
        "password": password,
        "mode": "cookie"
    }

    const promise = axios({
        url: "https://delta12.cc/auth/login",
        method: "POST",
        headers: {
        },
        data: auth,
    });
    const dataPromise = promise.then((res) => {
        //window.localStorage.setItem("accessToken", res.data.data.access_token);
        return res;
    });
    return dataPromise;
    // .catch((err) => { return err; });
}

const postData = async (body, collection) => {
    await instance.post("/items/" + collection, body)
        .then((res) => {
            console.log(res);
            console.log(res.data.data.id);
            localStorage.setItem('id', res.data.data.id);
        })
        .catch((err) => { console.log(err) });
};


const updateData = async (id, body) => {
    await instance.patch("/items/angebote/" + id, body)
        .then((res) => {
            console.log(res);
        })
        .catch((err) => { console.log(err) });
};

export { postData };