import React, { useReducer, useEffect } from 'react'
import './angebot.scss';
import Textfield from '../../components/Textfield';
import Textarea from '../../components/Textarea';
import Button from '../../components/Button';

import { useAuth } from '../../config/FormContext';
import { useNavigate } from "react-router-dom";
import Select from '../../components/Select';
const initialState = {
    titel: '',
    teaser: '',
    bschreibung: '',
    type: 'Angebot',
}


const options = [
    { value: 'Angebot', text: 'Angebot' },
    { value: 'Event', text: 'Event' },

];
function reducer(state, action) {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state, [action.key]: action.value,
            }
        case 'REPLACE':
            return action.payload;
        default:
            return state
    }
}

export default function Angebot() {
    const [reducerState, dispatch] = useReducer(reducer, initialState);
    const { tmpSave } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        tmpSave({ angebot: reducerState })

        navigate('/details')
    }

    useEffect(() => {
        const { angebot } = JSON.parse(window.localStorage.getItem('formData'));
        dispatch({ type: 'REPLACE', payload: { ...initialState, ...angebot } })
    }, []);

    return (
        <div className='add-new'>
            <h1>Angebot erfassen</h1>
            <p>Fülle hier alle wichtigen Informationen zu deinem Angebot ein.</p>

            <form onSubmit={e => { handleSubmit(e) }}>
                <Textfield
                    value={reducerState.titel}
                    onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'titel',
                        })}
                    placeholder="Titel" />
                <Select options={options} value={reducerState.type} onChange={(e) =>
                    dispatch({
                        type: 'UPDATE',
                        value: e,
                        key: 'type',
                    })} />
                <Textarea
                    value={reducerState.teaser}
                    onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'teaser',
                        })}
                    placeholder="Teaser"
                />
                <Textarea
                    value={reducerState.beschreibung}
                    onChange={(e) =>
                        dispatch({
                            type: 'UPDATE',
                            value: e,
                            key: 'beschreibung',
                        })}
                    placeholder="Beschreibung"
                    rows="10" />
                <Button
                    type='submit'
                    value='weiter'
                />
            </form>
        </div>
    )
}
