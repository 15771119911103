import React, { useReducer, useEffect, useState } from 'react'
import './datahub.scss';
import Textfield from '../../components/Textfield';
import Button from '../../components/Button';

import { useAuth } from '../../config/FormContext';
import { useNavigate } from "react-router-dom";

import instance from '../../config/axiosConfig';
import axios from 'axios';

import { Link } from 'react-router-dom';

import { iterativeImageCompression, toDataURL, dataURLtoFile } from '../../config/image-helper';


export default function DataHub() {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const WP_ENDPOINT = process.env.REACT_APP_WP_API_ENDPOINT;


    const client = axios.create({
        baseURL: `${WP_ENDPOINT}/wp-json/bikegenoss/v1/`,
    });

    const [data, setData] = useState(null);
    const [wpid, setWpid] = useState('');
    const [imageId, setImageId] = useState('');
    const [checked, setChecked] = useState(false);

    const getData = async (id) => {
        await instance.get("https://meta.et4.de/rest.ashx/search/?experience=bikegenoss&licensekey=BG67LUG82FHT3&type=Tour&template=ET2014A.json")
            .then((res) => {
                //setFormData(res.data.data)
                const response = res.data;
                console.log(response.items)
                setData(response.items);
            })
            .catch((err) => { console.log(err) });
    };

    const numberOfDates = (obj) => {
        if (obj == null) return 0;
        return Object.keys(obj).length;
    }

    const printValue = (obj) => {
        if (obj === "") return "-";
        return obj;
    }

    function removeEmptyValues(object) {
        for (var key in object) {
            if (object.hasOwnProperty(key)) {
                var value = object[key];
                if (value === null || value === undefined || value === '') {
                    delete object[key];
                }
            }
        }
    }


    const mapObject = async (obj) => {
        let body = {};

        body.id = obj.id;
        if (wpid !== undefined && wpid !== "") {
            body.wp_id = parseInt(wpid);
        } body.ratings = JSON.stringify(obj.ratings);
        body.title = obj.title;
        body.categories = obj.categories;
        body.media_objects = obj.media_objects;
        body.duration = parseInt(obj.duration);
        body.length = parseInt(obj.length);
        body.elevationMin = parseInt(obj.elevationMin);
        body.elevationMax = parseInt(obj.elevationMax);
        body.totalAscent = parseInt(obj.totalAscent);
        body.totalDescent = parseInt(obj.totalDescent);
        body.texts = JSON.stringify(obj.texts);
        if (checked) {
            let headerimage = await getCompressedImage(obj);
            body.headerbild = API_ENDPOINT + "/assets/" + headerimage + '.jpg';
        }


        removeEmptyValues(body);
        console.log(body);

        postData(body);
    }



    const postData = async (body) => {
        var form_data = new FormData();

        for (var key in body) {
            form_data.append(key, body[key]);
        }

        await client.post("/touren/", form_data)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => { console.log(err) });
    };


    useEffect(() => {
        localStorage.removeItem('id');

        getData();

    }, []);

    const getImages = (obj) => {
        const images = { default: null, imagegallery: [] };
        console.log(obj.media_objects);
        obj.media_objects.forEach((item) => {
            if (item.rel === 'default') {
                images.default = item;

            }
            if (item.rel === 'imagegallery') {
                images.imagegallery.push(item);
            }
        });
        console.log(images);

        let fileArr = [];

        let fn = obj.title.replace(/\s+/g, '-').toLowerCase().replace(/\u00e4/g, "ae").replace(/\u00fc/g, "ue").replace(/\u00f6/g, "oe");

        const img = images.default ? images.default : images.imagegallery[0];
        toDataURL(img.url).then(async (dataUrl) => {
            let fileData = dataURLtoFile(dataUrl, fn);
            fileArr.push(fileData)
            const file = await iterativeImageCompression(fileData);
            const formData = new FormData();
            formData.append('file', file);
            postFile(formData, img.url);
        });


    }

    const getCompressedImage = async (obj) => {
        const images = { default: null, imagegallery: [] };
        console.log(obj.media_objects);
        obj.media_objects.forEach((item) => {
            if (item.rel === 'default') {
                images.default = item;

            }
            if (item.rel === 'imagegallery') {
                images.imagegallery.push(item);
            }
        });
        console.log(images);

        const img = images.default ? images.default : images.imagegallery[0];

        return await axios.get("/items/DCH_Images?filter[dch_link][_eq]=" + img.url)
            .then((res) => {
                //setFormData(res.data.data)
                const response = res.data;
                console.log(response.data[0].compressed_image);
                return response.data[0].compressed_image;
            })
            .catch((err) => { console.log(err) });

    }


    const postFile = async (formData, url) => {
        try {
            const res = await axios.post('/files', formData);
            console.log(res.data.data.id);
            setImageId(res.data.data.id);
            postImageData({ compressed_image: res.data.data.id, dch_link: url })
        } catch (error) {
            console.error(error);
        }
    }

    const postImageData = async (body) => {
        var form_data = new FormData();

        await axios.post("/items/DCH_Images/", body)
            .then((res) => {
                console.log(res);
            })
            .catch((err) => { console.log(err) });
    };

    return (
        <div className='add-new'>
            <h1>Übersicht</h1>
            <p>Fülle bitte zuerst deine Kontaktdaten aus, so können wir die Angebote der richtigen Person zuordnen. Auf den nächsten Seiten kannst du alle wichtigen Informationen zu deinem Angebot erfassen.</p>
            <Textfield
                value={wpid}
                onChange={(e) => setWpid(e)}
                placeholder="WordPress Id" />
            <label>
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={(prev) => setChecked(!checked)}
                />
                Update Headerimage
            </label>
            <div className={"row row--header"}>


                <span>Name</span>
                <span>Organisation</span>
                <span className="narrow">#Termine</span>
                <span className="narrow">Bild</span>
                <span className="narrow">Logo</span>
                <span className="narrow">Sync</span>



            </div>
            {data ? data.map((item) => (
                <div className={"row"} key={item.id}>



                    {item.title ?
                        <span>{item.title}</span> : null}
                    {item.id ?
                        <span>{item.id}</span> : null}

                    <span className="narrow" onClick={() => getImages(item)}>get Images</span>

                    <span className="narrow" onClick={() => mapObject(item)}>sync</span>

                </div>
            )) : null
            }

        </div >
    )
}
