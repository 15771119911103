import React, { useState } from 'react'
import Button from '../../components/Button';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../config/FormContext';
import './file.scss';
import Compressor from 'compressorjs';

export default function Files() {
    const navigate = useNavigate();
    const { setHeaderbildContext, headerBildContext, setLogoContext, setGalleryContext, finalPost } = useAuth();

    const [disable, setDisable] = useState(false);

    const [logo, setLogo] = useState(null);
    const [logoFormData, setLogoFormData] = useState(null);
    const [headerbild, setHeaderbild] = useState(null);
    const [headerbildFormData, setHeaderbildFormData] = useState(null);
    const [gallery, setGallery] = useState(null);
    const [galleryFormData, setGalleryFormData] = useState(null);
    const handleSubmit = (e) => {
        e.preventDefault();
        const promises = [];
        if (logoFormData !== null) {
            promises.push(postFile(logoFormData, 'logo'));

        }
        if (headerbildFormData !== null) {
            promises.push(postFile(headerbildFormData, 'headerbild'));

        }
        if (galleryFormData !== null) {
            promises.push(postFile(galleryFormData, 'gallery'));
        }

        Promise.all(promises).then(setTimeout(() => {
            finalPost();
        }, 1000));

        navigate('/success')
    }

    const handleFileLogo = (e) => {
        console.log(e.target.files)
        const files = e.target.files;
        setLogo(files[0].name)
        const formData = new FormData();
        formData.append('file', files[0]);
        setLogoFormData(formData);

    }
    const handleFileHeaderbild = (e) => {
        console.log(e.target.files)
        const files = e.target.files;
        setHeaderbild(files[0].name)
        const formData = new FormData();
        formData.append('file', files[0]);
        setHeaderbildFormData(formData);

    }

    const handleFileGallery = (e) => {
        console.log(e.target.files)
        const files = e.target.files;
        setGallery(files[0].name)
        const formData = new FormData();
        formData.append('file', files);
        setGalleryFormData(formData);

    }

    const compressImage = (file, quality) => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: quality,
                maxWidth: 2400,
                maxHeight: 2400,
                success: (result) => {
                    resolve(new File([result], file.name, { type: result.type }));
                },
                error: (error) => reject(error)
            })
        })
    }

    const onFileInputChange = async (e) => {
        setDisable(true);
        let counter = 10;
        let quality = 1;
        let size = 100;
        let file;
        let factor = 1;
        let maxSize = 1.2;
        let prevSize = null;
        while (counter > 0 && size > maxSize) {
            counter--;
            quality = Math.round((quality - factor * 0.05) * 20) / 20;
            file = await compressImage(e.target.files[0], quality);
            size = file.size / (1000 * 1000);
            factor = Math.max(Math.round(size / maxSize), 1);
            if (size === prevSize) break;
            prevSize = size;
        }

        console.log(file);

        const files = e.target.files;
        setHeaderbild(files[0].name)
        const formData = new FormData();
        formData.append('file', file);
        setHeaderbildFormData(formData);
        setDisable(false);

    }

    const postFile = async (formData, f) => {
        try {
            const res = await axios.post('/files', formData);
            if (res.data.data.length > 0) {
                const arr = res.data.data;
                let result = arr.map(a => a.id);
                localStorage.setItem(f, result);
            } else {
                localStorage.setItem(f, res.data.data.id);
            }
        } catch (error) {
            console.error(error);
        }


    }



    return (
        <div className='add-new'>
            <h1>Dateien</h1>
            <p>Hier kannst du noch Bilder und Logo zu deinem Angebot hinzufügen.</p>

            <form onSubmit={e => { handleSubmit(e) }}>
                <div>
                    <div className="file-input">
                        <input type="file" id="logo" className="file" onChange={handleFileLogo} />
                        <label htmlFor="logo">Logo hochladen</label>
                    </div>
                    <p className="file-name">{logo ? logo : null}</p>
                </div>
                <div>
                    <div className="file-input">
                        <input type="file" id="headerbild" className="file" onChange={onFileInputChange} />
                        <label htmlFor="headerbild">Headerbild hochladen</label>
                    </div>
                    <p className="file-name">{headerbild ? headerbild : null}</p>
                </div>
                <Button
                    type='submit'
                    value='weiter'
                    disabled={disable}
                />
            </form>
        </div>
    )
}
