import './button.scss';

const Button = (props) => {
    const { value, type, disabled } = props;

    return <input value={value} type={type} disabled={disabled} />;
};

Button.defaultProps = {
    type: "submit"
}

export default Button;